define('weather-fire/utils/datetime', ['exports'], function (exports) {

  'use strict';

  exports.dateTimeStringToUTC = dateTimeStringToUTC;

  function dateTimeStringToUTC(dateTimeString) {
    return moment.utc(value).format('YYYYMMDDHHMMSS');
  }

});