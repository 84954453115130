define('weather-fire/models/current', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    exports['default'] = DS['default'].Model.extend({
        datetime: DS['default'].attr('date'),
        dewpoint: DS['default'].attr('number'),
        evapotranspiration: DS['default'].attr('number'),
        heatIndex: DS['default'].attr('number'),
        humidity: DS['default'].attr('number'),
        lastdata: DS['default'].attr('string'),
        normalizedPressure: DS['default'].attr('number'),
        pressure: DS['default'].attr('number'),
        rain0rate: DS['default'].attr('number'),
        raintotal: DS['default'].attr('number'),
        rain0totalSumday: DS['default'].attr('number'),
        rain0totalMonthsum: DS['default'].attr('number'),
        rain0TotalLast24Hours: DS['default'].attr('number'),
        solarRadiation: DS['default'].attr('number'),
        tempF: DS['default'].attr('number'),
        tempFDaysMax: DS['default'].attr('number'),
        tempFDaysMaxTime: DS['default'].attr('moment'),
        tempFDaysMin: DS['default'].attr('number'),
        tempFDaysMinTime: DS['default'].attr('moment'),
        uptime: DS['default'].attr('date'),
        uvindex: DS['default'].attr('number'),
        wind: DS['default'].attr('number'),
        windAverage: DS['default'].attr('number'),
        windAvgLast10Mins: DS['default'].attr('number'),
        windChill: DS['default'].attr('number'),
        windChillAvgLast30Mins: DS['default'].attr('number'),
        windChillAvgLastHour: DS['default'].attr('number'),
        windChillMinToday: DS['default'].attr('number'),
        windChillMinTodayTime: DS['default'].attr('date'),
        windDirection: DS['default'].attr('number'),
        windMaxLast10Mins: DS['default'].attr('number'),
        windMaxLast2Mins: DS['default'].attr('number'),
        windMaxLast5Mins: DS['default'].attr('number'),
        windSpeedDaysMax: DS['default'].attr('number'),
        windSpeedDaysMaxTime: DS['default'].attr('moment'),
        windSpeedDaysMin: DS['default'].attr('number'),
        windSpeedDaysMinTime: DS['default'].attr('moment') });

});