define('weather-fire/pods/application/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('current');
    }
  });

  //setup Controller
  // - make ajax request
  // - controller.set(wundergroundYesterday, ajaxResults)

});