define('weather-fire/helpers/format-datetime', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].HTMLBars.makeBoundHelper(function (utcDate) {
    if (utcDate[0] instanceof moment) {
      return utcDate[0].format('h:mm:ss a');
    } else {
      return moment(utcDate[0]).format('MM/DD h:mm:ss a z');
    }
  });

});