define('weather-fire/pods/application/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 1,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Last Reading: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-6");
          var el3 = dom.createTextNode("\n\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("Today's Temperature");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Current Temperature: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Today's Max: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" @ ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Today's Min: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" @ ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-10");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("table");
          dom.setAttribute(el5,"class","table table-hover ");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tbody");
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("(Temperature-Humidity) Difference");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("(Dewpoint-Humidity) Difference");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("Humidity");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("Dewpoint");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("Pressure");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("Heat Index");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode("Windchill");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode(" ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-6");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("Today's Wind");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Current Wind: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Max Wind Last: 2mins=");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(", data10min=");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Today's Max: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" @ ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("pre");
          var el2 = dom.createTextNode("\n  datetime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  dewpoint - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  evapotranspiration - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  heatIndex - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  humidity - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  lastdata - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  normalizedPressure - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  pressure - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  rain0rate - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  rain0totalSumday - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  rain0TotalLast24Hours - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  rain0totalMonthsum - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  raintotal - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  solarRadiation - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  tempF - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  tempFDaysMax - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  tempFDaysMaxTime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  tempFDaysMaxTimeDate - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  tempFDaysMin - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  tempFDaysMinTime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  uptime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  uvindex - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  wind - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windAverage - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windAvgLast10Mins - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windChill - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windChillAvgLast30Mins - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windChillAvgLastHour - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windChillMinToday - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windChillMinTodayTime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windDirection - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windMaxLast10Mins - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windMaxLast2Mins - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windMaxLast5Mins - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windSpeedDaysMax - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windSpeedDaysMaxTime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windSpeedDaysMin - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  windSpeedDaysMinTime - ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement, blockArguments) {
          var dom = env.dom;
          var hooks = env.hooks, set = hooks.set, get = hooks.get, inline = hooks.inline, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [5]);
          var element3 = dom.childAt(element1, [7]);
          var element4 = dom.childAt(element1, [9, 1, 1, 1]);
          var element5 = dom.childAt(element0, [3]);
          var element6 = dom.childAt(element5, [5]);
          var element7 = dom.childAt(element5, [7]);
          var element8 = dom.childAt(fragment, [5]);
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          var morph1 = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
          var morph2 = dom.createMorphAt(element2,1,1);
          var morph3 = dom.createMorphAt(element2,3,3);
          var morph4 = dom.createMorphAt(element2,5,5);
          var morph5 = dom.createMorphAt(element3,1,1);
          var morph6 = dom.createMorphAt(element3,3,3);
          var morph7 = dom.createMorphAt(element3,5,5);
          var morph8 = dom.createMorphAt(dom.childAt(element4, [1, 1]),1,1);
          var morph9 = dom.createMorphAt(dom.childAt(element4, [3, 1]),1,1);
          var morph10 = dom.createMorphAt(dom.childAt(element4, [5, 1]),1,1);
          var morph11 = dom.createMorphAt(dom.childAt(element4, [7, 1]),1,1);
          var morph12 = dom.createMorphAt(dom.childAt(element4, [9, 1]),1,1);
          var morph13 = dom.createMorphAt(dom.childAt(element4, [11, 1]),1,1);
          var morph14 = dom.createMorphAt(dom.childAt(element4, [13, 1]),1,1);
          var morph15 = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
          var morph16 = dom.createMorphAt(element6,1,1);
          var morph17 = dom.createMorphAt(element6,3,3);
          var morph18 = dom.createMorphAt(element7,1,1);
          var morph19 = dom.createMorphAt(element7,3,3);
          var morph20 = dom.createMorphAt(element7,5,5);
          var morph21 = dom.createMorphAt(element8,1,1);
          var morph22 = dom.createMorphAt(element8,3,3);
          var morph23 = dom.createMorphAt(element8,5,5);
          var morph24 = dom.createMorphAt(element8,7,7);
          var morph25 = dom.createMorphAt(element8,9,9);
          var morph26 = dom.createMorphAt(element8,11,11);
          var morph27 = dom.createMorphAt(element8,13,13);
          var morph28 = dom.createMorphAt(element8,15,15);
          var morph29 = dom.createMorphAt(element8,17,17);
          var morph30 = dom.createMorphAt(element8,19,19);
          var morph31 = dom.createMorphAt(element8,21,21);
          var morph32 = dom.createMorphAt(element8,23,23);
          var morph33 = dom.createMorphAt(element8,25,25);
          var morph34 = dom.createMorphAt(element8,27,27);
          var morph35 = dom.createMorphAt(element8,29,29);
          var morph36 = dom.createMorphAt(element8,31,31);
          var morph37 = dom.createMorphAt(element8,33,33);
          var morph38 = dom.createMorphAt(element8,35,35);
          var morph39 = dom.createMorphAt(element8,37,37);
          var morph40 = dom.createMorphAt(element8,39,39);
          var morph41 = dom.createMorphAt(element8,41,41);
          var morph42 = dom.createMorphAt(element8,43,43);
          var morph43 = dom.createMorphAt(element8,45,45);
          var morph44 = dom.createMorphAt(element8,47,47);
          var morph45 = dom.createMorphAt(element8,49,49);
          var morph46 = dom.createMorphAt(element8,51,51);
          var morph47 = dom.createMorphAt(element8,53,53);
          var morph48 = dom.createMorphAt(element8,55,55);
          var morph49 = dom.createMorphAt(element8,57,57);
          var morph50 = dom.createMorphAt(element8,59,59);
          var morph51 = dom.createMorphAt(element8,61,61);
          var morph52 = dom.createMorphAt(element8,63,63);
          var morph53 = dom.createMorphAt(element8,65,65);
          var morph54 = dom.createMorphAt(element8,67,67);
          var morph55 = dom.createMorphAt(element8,69,69);
          var morph56 = dom.createMorphAt(element8,71,71);
          var morph57 = dom.createMorphAt(element8,73,73);
          var morph58 = dom.createMorphAt(element8,75,75);
          set(env, context, "dataPoint", blockArguments[0]);
          inline(env, morph0, context, "format-datetime", [get(env, context, "dataPoint.datetime")], {});
          content(env, morph1, context, "dataPoint.tempF");
          content(env, morph2, context, "dataPoint.tempFDaysMax");
          inline(env, morph3, context, "format-timesince", [get(env, context, "dataPoint.tempFDaysMaxTime")], {});
          inline(env, morph4, context, "format-datetime", [get(env, context, "dataPoint.tempFDaysMaxTime")], {});
          content(env, morph5, context, "dataPoint.tempFDaysMin");
          inline(env, morph6, context, "format-timesince", [get(env, context, "dataPoint.tempFDaysMinTime")], {});
          inline(env, morph7, context, "format-datetime", [get(env, context, "dataPoint.tempFDaysMinTime")], {});
          inline(env, morph8, context, "format-temperature-dewpoint", [get(env, context, "dataPoint.tempF"), get(env, context, "dataPoint.dewpoint")], {});
          inline(env, morph9, context, "format-humidity-dewpoint", [get(env, context, "dataPoint.humidity"), get(env, context, "dataPoint.dewpoint")], {});
          content(env, morph10, context, "dataPoint.humidity");
          content(env, morph11, context, "dataPoint.dewpoint");
          content(env, morph12, context, "dataPoint.pressure");
          content(env, morph13, context, "dataPoint.heatIndex");
          content(env, morph14, context, "dataPoint.windChill");
          content(env, morph15, context, "dataPoint.wind");
          content(env, morph16, context, "dataPoint.windMaxLast2Mins");
          content(env, morph17, context, "dataPoint.windMaxLast10Mins");
          content(env, morph18, context, "dataPoint.windSpeedDaysMax");
          inline(env, morph19, context, "format-timesince", [get(env, context, "dataPoint.windSpeedDaysMaxTime")], {});
          inline(env, morph20, context, "format-datetime", [get(env, context, "dataPoint.windSpeedDaysMaxTime")], {});
          content(env, morph21, context, "dataPoint.datetime");
          content(env, morph22, context, "dataPoint.dewpoint");
          content(env, morph23, context, "dataPoint.evapotranspiration");
          content(env, morph24, context, "dataPoint.heatIndex");
          content(env, morph25, context, "dataPoint.humidity");
          content(env, morph26, context, "dataPoint.lastdata");
          content(env, morph27, context, "dataPoint.normalizedPressure");
          content(env, morph28, context, "dataPoint.pressure");
          content(env, morph29, context, "dataPoint.rain0rate");
          content(env, morph30, context, "dataPoint.rain0totalSumday");
          content(env, morph31, context, "dataPoint.rain0TotalLast24Hours");
          content(env, morph32, context, "dataPoint.rain0totalMonthsum");
          content(env, morph33, context, "dataPoint.raintotal");
          content(env, morph34, context, "dataPoint.solarRadiation");
          content(env, morph35, context, "dataPoint.tempF");
          content(env, morph36, context, "dataPoint.tempFDaysMax");
          content(env, morph37, context, "dataPoint.tempFDaysMaxTime");
          content(env, morph38, context, "dataPoint.tempFDaysMaxTimeDate");
          content(env, morph39, context, "dataPoint.tempFDaysMin");
          content(env, morph40, context, "dataPoint.tempFDaysMinTime");
          content(env, morph41, context, "dataPoint.uptime");
          content(env, morph42, context, "dataPoint.uvindex");
          content(env, morph43, context, "dataPoint.wind");
          content(env, morph44, context, "dataPoint.windAverage");
          content(env, morph45, context, "dataPoint.windAvgLast10Mins");
          content(env, morph46, context, "dataPoint.windChill");
          content(env, morph47, context, "dataPoint.windChillAvgLast30Mins");
          content(env, morph48, context, "dataPoint.windChillAvgLastHour");
          content(env, morph49, context, "dataPoint.windChillMinToday");
          content(env, morph50, context, "dataPoint.windChillMinTodayTime");
          content(env, morph51, context, "dataPoint.windDirection");
          content(env, morph52, context, "dataPoint.windMaxLast10Mins");
          content(env, morph53, context, "dataPoint.windMaxLast2Mins");
          content(env, morph54, context, "dataPoint.windMaxLast5Mins");
          content(env, morph55, context, "dataPoint.windSpeedDaysMax");
          content(env, morph56, context, "dataPoint.windSpeedDaysMaxTime");
          content(env, morph57, context, "dataPoint.windSpeedDaysMin");
          content(env, morph58, context, "dataPoint.windSpeedDaysMinTime");
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","container-fluid");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2,"id","title");
        var el3 = dom.createTextNode("weather");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element9 = dom.childAt(fragment, [1]);
        var morph0 = dom.createMorphAt(element9,3,3);
        var morph1 = dom.createMorphAt(element9,5,5);
        content(env, morph0, context, "outlet");
        block(env, morph1, context, "each", [get(env, context, "model")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});